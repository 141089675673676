<template>
  <div class="pxp__auth">
    <div class="auth__form">
      <Card size="small" class="auth__card">
        <template v-slot:header>
          <div class="auth__header">
            <img
              src="@/assets/images/pxp-logo.svg"
              alt="pagexpage logo"
              class="card__logo"
            />
          </div>
        </template>

        <template v-slot:body>
          <template v-if="authMode === 'signin'">
            <SignInForm
              @login="onLogin"
              @forgot-password="authMode = 'forgotPassword'"
            />
          </template>

          <template v-else-if="authMode === 'signup'">
            <template v-if="signUpSuccess">
              <CheckEmail :email="emailShow"/>
            </template>
            <template v-else>
              <SignUpForm @registration="onRegistration" />
            </template>
          </template>

          <template v-else-if="authMode === 'forgotPassword'">
            <ResetPasswordForm />
          </template>
        </template>

        <template v-slot:footer>
          <div class="auth__account-question">
            <hr />
            <template v-if="authMode === 'signin'">
              <p>
                Don’t have an account yet?<br />
                <Button
                  style-modifiers="text"
                  text="Sign up"
                  class="sign-in__sign-up"
                  @click="authMode = 'signup'"
                />
              </p>
            </template>
            <template v-else-if="authMode === 'signup'">
              <p>
                By signing up, you agree to our
                <Button
                  style-modifiers="text"
                  text="terms of service"
                  class="sign-in__terms"
                  @click="showTerms = true"
                />.

                <br />

                If you already have an account, please
                <Button
                  style-modifiers="text"
                  text="Sign in"
                  class="sign-in__sign-up"
                  @click="authMode = 'signin'"
                />.
              </p>
            </template>
            <template v-else-if="authMode === 'forgotPassword'">
              <p>
                Return to
                <Button
                  style-modifiers="text"
                  text="sign in"
                  class="sign-in__sign-up"
                  @click="authMode = 'signin'"
                />
                <br />
                Don’t have an account yet?
                <Button
                  style-modifiers="text"
                  text="Sign up"
                  class="sign-in__sign-up"
                  @click="authMode = 'signup'"
                />
              </p>
            </template>
          </div>
        </template>
      </Card>
    </div>
    <div class="auth__promo">
      <template v-if="authMode === 'signup'">
        <SignUpPromo/>
      </template>
      <template v-else>
        <SignInPromo/>
      </template>

    </div>
  </div>
  <Modal id="terms" v-model:show="showTerms" header>
    <template v-slot:header>
      <div class="terms-services-header">
        <h1>Terms of services</h1>
      </div>
    </template>
    <div class="terms-services-body">
    </div>
  </Modal>
</template>

<script>
import Button from "@/components/UI-Kit/Button";
import { useRouter } from "vue-router";
import { ref, inject, watch, nextTick } from "vue";
import SignInForm from "@/components/Auth/SignInForm";
import SignUpForm from "@/components/Auth/SignUpForm";

import Card from "@/components/UI-Kit/Card";
import Modal from "@/components/UI-Kit/Modal";
import ResetPasswordForm from "@/components/Auth/ResetPasswordForm";
import CheckEmail from "@/components/Auth/CheckEmail";
import SignUpPromo from "@/components/Auth/Promo/SignUpPromo";
import SignInPromo from "@/components/Auth/Promo/SignInPromo";

export default {
  name: "Auth",
  components: {
    SignInPromo,
    SignUpPromo,
    CheckEmail,
    ResetPasswordForm,
    Modal,
    Card,
    Button,
    SignInForm,
    SignUpForm
  },
  setup() {
    const router = useRouter();
    const authMode = ref("signin");
    const showTerms = ref(false);
    const signUpSuccess = ref(false);
    const emailShow = ref('')

    const appInit = inject("appInit");
    const appLoad = inject("appLoad");

    const onLogin = function() {
      let collaborationData = localStorage.getItem("collaborationData")

      if (collaborationData !== null) {
        collaborationData = JSON.parse(collaborationData)

        router.push(`/join/${collaborationData.workspaceId}?key=${collaborationData.workspaceKey}`)
      } else {
        appInit()
        watch(appLoad, (value) => {
          if (!value) {
            nextTick(() => {
              router.push({
                name: "WorkspaceRedirect"
              })
            })
          }
        })
      }
    };

    const onRegistration = function(email) {
      signUpSuccess.value = true;
      emailShow.value = email
    };

    return {
      authMode,
      showTerms,
      onLogin,
      onRegistration,
      signUpSuccess,
      emailShow
    };
  }
};
</script>

<style scoped lang="scss">
@import "~uikit/src/scss/variables.scss";
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/color-scheme.scss";
@import "~@/assets/styles/utility.scss";

.pxp__auth {
  @include suppress-scroll;
  display: grid;
  grid-template-areas: "auth__form auth__promo";
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100vh;
}

.auth__form {
  grid-area: auth__form;
  display: grid;
  padding: 8% 16%;
  max-height: 100vh;
  background-color: $pxp-blue-added-color;
}

.auth__promo {
  @include suppress-scroll;
  grid-area: auth__promo;
  background-color: $pxp-gray-added-color;
  padding: 40px 60px 60px 40px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: scroll;
}

.auth__card {
  box-sizing: border-box;
  border-radius: 8px;
  place-self: center center;
  width: 100%;
  max-height: 100%;
  padding: 32px;
  overflow-y: scroll;
}

.card__logo {
  width: 182px;
  height: 32px;
  float: left;
}

.auth__account-question {
  p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 32.5px;
    text-align: left;
    color: black;
  }
  .sign-in__sign-up,
  .sign-in__terms {
    font-weight: 700;
    display: inline-block;
    width: auto;
    margin-top: -1px;
  }
  margin-bottom: -5px;
}

.auth__header {
}

.terms-services-header {
  padding: 32px;
}

.terms-services-body {
  height: 400px;
}

h1 {
  margin-bottom: 40px;
}
</style>
