<template>
  <div
    :class="[styleModifiers.length > 0 ? 'uk-alert-' + styleModifiers : '']"
    uk-alert
    class="pxp__alert"
  >
    <template v-if="isClose">
      <a class="uk-alert-close" uk-close></a>
    </template>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    styleModifiers: {
      type: String,
      required: false,
      default: "",
      validator: mode => {
        return ["success", "danger", "primary", "warning"].indexOf(mode) !== -1;
      }
    },
    isClose: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>

<style scoped>
.pxp__alert {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
</style>
