import { Icon } from "@/components/UI-Kit/Helper classes/icon";

export const authIconSetup = function() {
  const keyIcon = new Icon("./icons/key.svg", "key", "");
  const emailIcon = new Icon("./icons/email.svg", "email", "");
  const universityIcon = new Icon("./icons/school.svg", "school", "");
  const userIcon = new Icon("./icons/user.svg", "user", "");

  return {
    keyIcon,
    emailIcon,
    universityIcon,
    userIcon
  };
};
