<template>
  <IconNew name="logo-signup-promo" class="promo-logo"></IconNew>

  <div class="promo__title">
    <span>
      Page x Page supports PhD students and researchers<br>
      accomplish their research goals faster and more effectively
    </span>
  </div>

  <div class="line"/>

  <div class="free__trial">
    <div class="green__title">
      <span>14 DAY FREE TRIAL</span>
    </div>

    <div class="grid">

      <template v-for="(item, index) in freeTrial" :key="'promo-signup-free-trial-' + index">
        <div class="grid__element">
          <IconNew  name="promo-signup-check" class="check"/>
          <div class="check__text">
            <span v-html="item"/>
          </div>
        </div>

      </template>

    </div>

  </div>
  <div class="line"/>

  <div class="quotes">

    <template v-for="(quote, index) in quotes" :key="'promo-signup-quote-' + index">
      <div class="quote">
        <div class="quote__text">
          <span v-html="quote.content"/>
        </div>
        <div class="quote__author">
          <span v-html="quote.author"/>
        </div>
      </div>
    </template>


  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";
import Icon from "@/components/UI-Kit/Icon";
export default {
  name: "SignUpPromo",
  components: {Icon, IconNew},
  setup() {
    const quotes = [
      {
        content: "“This App not only provides tools to help students avoid the common PhD pitfalls but also focuses on getting the most out of their PhD journey, equipping them for the future.”",
        author: "— Dr Alan Henry, PhD in Marine Energy,<br>Queen’s University, Belfast"
      },
      {
        content: "“I learnt a lot! Thanks to the blueprints that this course gave me, I feel more confident to go through the PhD journey now!”",
        author: "— Fariba Foolad, postgraduate student in Digital Marketing,<br>National University of Ireland, Galway"
      },
      {
        content: '“Through his knowledge, my research coach provides excellent and practical advice, not only with things directly related to my PhD but also on more general aspects of academic life.”',
        author: '— Neil Ackerman, PhD Carnegie Scholar in Archaeology,<br>University of the Highlands and Islands'
      }
    ]
    const freeTrial = [
      'Full Access<br>to PhD App',
      'Step-By-Step<br>Online Course',
      'Online<br>Community',
      'Upgrade to<br>Add Coaching'
    ]

    return {
      quotes,
      freeTrial
    }
  }
}
</script>

<style scoped>
.check__text,
.green__title {
  text-align: center;
}

.check__text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #000000;

}

.promo__title {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #13246E;
  margin-top: 20px;
}

.quote {
  padding-bottom: 60px;
}

.qoute__author {
  margin-top: 10px;
  float: right;
  color: gray;
  text-align: right;
}

.pxp__auth {
  display: grid;
  grid-template-areas: "auth__form auth__promo";
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 100vh;
}

.grid {
  display: grid;
  grid-template-areas: "grid__element";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
}

.green__title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;

  color: #0CAC60;

}

.check {
  width: 48px;
  height: 48px;
  margin-top: 16px;
}

.free__trial {
  background-color: white;
  border-radius: 10px;
  padding: 20px 0 20px 0;
  width: 100%;
}

.line {
  width: 64px;
  border: 1px solid #CCCFDB;
  margin: 30px auto 30px auto;
}

.promo-logo {
  width: 36px;
  height: 34px;
  margin: auto;
}

.quote__text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.quote__author {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #80869D;
}
</style>