<template>
  <div class="text-input"
       :class="{
          'text-input_error': error,
       }">
    <div class="uk-inline text-input__input-wrapper">
      <template v-if="label">
        <label :for="id">{{ label }}</label>
      </template>

      <template v-if="icon">
        <Icon
          :size="16"
          :icon="icon"
          class="uk-form-icon text-input__icon"
          :class="[
            mode? 'text-input__icon_' + mode: '',
            error? 'text-input__icon_error': ''
          ]"
        />
      </template>

      <input
        :id="id"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        class="uk-input text-input__input"
        :class="[
            mode? 'text-input__input_' + mode: '',
            error? 'text-input__input_error': ''
        ]"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focusout="checkValue()"
        @focusin="resetCheck()"
      />
    </div>

    <div
      v-if="msg.length > 0"
      class="text-input__help"
      :class="[error ? 'text-input__help_error' : '']"
    >
      <p>{{ msg }}</p>
    </div>
  </div>
</template>

<script>
import { Icon as iconClass } from "@/components/UI-Kit/Helper classes/icon";
import Icon from "@/components/UI-Kit/Icon";
import { computed, ref } from "vue";

const tiMode = ["auth", "default"];

export default {
  name: "TextInput",
  components: { Icon },
  props: {
    // v-model
    modelValue: {
      type: String
    },

    // input
    label: {
      type: [String, undefined],
      required: false,
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },

    // icon
    icon: {
      type: [iconClass, undefined],
      required: false,
      default: undefined
    },

    // mode
    mode: {
      type: String,
      required: false,
      default: "default",
      validator: mode => {
        return tiMode.indexOf(mode) !== -1;
      }
    },

    // help
    validate: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const error = ref(false);
    const msg = ref("");

    const addMode = mode => {
      return {
        iconClass: "text-input__icon_" + mode,
        inputClass: "text-input__input_" + mode
      };
    };

    let { mainClass, iconClass, inputClass } = addMode(props.mode);

    const checkValue = async function() {
      if (props.modelValue.length > 0) {
        for (let v of props.validate) {
          let err = v(props.modelValue);
          if (err) {
            error.value = true;
            msg.value = err;
            break;
          }
        }
      }
    };

    const resetCheck = function() {
      error.value = false;
      msg.value = "";
    };

    return {
      checkValue,
      resetCheck,
      error,
      msg,
      addIconClasses: iconClass,
      addInputClasses: computed(
        () => inputClass + (error.value ? " text-input__input_error" : "")
      )
    };
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";

.text-input {
  width: 100%;
  position: relative;
}

.text-input__input-wrapper {
  width: 100%;
}

.text-input__input {

  &_auth {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 44px;

    &:focus {
      border: 1px solid #1d85d0;
    }
  }

  &_error {
    border: 1px solid #e8614b !important;
  }
}

.text-input__icon_auth {
  margin: auto 16px;
}


.text-input__help {
  max-width: 270px;
  max-height: 35px;
  box-sizing: border-box;

  position: absolute;
  top: 35px;
  left: 50%;
  transform:translate(-50%, 0);
  z-index: 100;

  padding: 1px 16px;
  border-radius: 24px;

  background-color: white;
  border: 1px solid #1d85d0;
  color: #1d85d0;


  p {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    white-space: nowrap;
  }

  &_error {
    border: 1px solid #e8614b;
    p {
      color: white !important;
    }
    background-color: #e8614b;
  }
}
</style>
